import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["loading", "creativeBusinessToggle"];

  initialize(){
    $(this.loadingTarget).hide();
  }

  toggleCreativeBusiness() {
    this.creativeBusinessToggleTarget.style.pointerEvents = "none";
    $(this.loadingTarget).show();
    let url = this.data.get("toggle-creative-business-path");
    Rails.ajax({
      type: 'PATCH',
      url: url,
      complete: () => {
        $(this.loadingTarget).hide();
        this.creativeBusinessToggleTarget.style.pointerEvents = "auto";
      }
    });
  }
}
