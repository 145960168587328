import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["dashboardFilterForm", "statusField", "userIdField"]

  submitForm(){
    document.querySelector('.dashboard-body .ui.loader').classList.add("active")
    Rails.fire(this.dashboardFilterFormTarget, 'submit')
  }

  selectMultiple(event){
    this.userIdFieldTarget.value = event.target.dataset.user;
    event.target.classList.add("active");
    $(event.target.closest(".user-select-wrapper")).addClass('active').siblings().removeClass('active');
    this._unselectUser()
    this.submitForm()
  }

  selectUser(event){
    var selectedUserId = event.target.value
    $(event.target.closest(".user-select-wrapper")).addClass('active').siblings().removeClass('active');
    this.userIdFieldTarget.value = selectedUserId
    this.submitForm()
  }

  selectState(event){
      var selectedStatus = event.target.dataset.status
      this.statusFieldTarget.value = selectedStatus
      this.submitForm()
  }

  _unselectUser(){
    document.getElementById("select-dashboard-user").selectedIndex = -1;
  }
}