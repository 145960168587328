import { ModalForm } from "./modal_form_controller"

export default class extends ModalForm {
  static targets = ['year', "make", "model", "valuation", "owner", "validatableInput"]
  connect() {
    if(this.element.dataset.modal == 'true')
      this.modalView = new DSModal(this.element.querySelector('.modal-container.ds-modal'));

    if(this.element.classList.contains('newly-inserted')){
      this.modalView.show();
      $(this.element).removeClass('newly-inserted');
    }else{
      $(this.element).addClass('modal-form');
      $(this.element).find('.summary-container').removeClass('hidden');
    }

    this._bindInputChangeValidation();

    if(this.element.classList.contains('persisted-record')){
      this._disableOwnerDropdown();
    }

    $('.dropdown').dropdown({
      onChange: function(value, text, $selectedItem) {
        $(this).trigger("focusout");
      }
    });

    setupVehicleFields();
    setupCheckboxes();
  }

  _disableOwnerDropdown() {
    $(this.element).find('.vehicle-summary-container .loan_application_vehicles_owner .dropdown').addClass('disabled');
  }

  _updateOwnerValue(){
    var ownerDropdown = this.element.querySelector("[data-field-name='owner']");
    var ownerSelectInput = $(ownerDropdown).find('select')[0];
    this[ownerDropdown.dataset.fieldName+"Target"].innerHTML = ownerSelectInput.options[ownerSelectInput.selectedIndex].text;
  }

  _updateSummaryFields(){
    super._updateSummaryFields();
    this._updateOwnerValue();
  }

  remove(){
    let _this = this;
    $('.collateral-details-form-container').one('cocoon:after-remove', function(e, removedItem, originalEvent){
      _this.modalView.hide();
    });
  }

  get summaryFields() {
    return ['year', "make", "model"];
  }
}
