import { Controller } from "stimulus"

export default class extends Controller {
  initialize() {
    this.getCreditReportSegment();
  }

  getCreditReportSegment() {
    Rails.ajax({
      type: 'GET',
      url: this.data.get('path')
    });
  }
}
