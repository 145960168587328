import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['addressFields']

  connect() {
    $(this.element).find(':input').inputmask()
    applyCurrencyInputmask($(this.element).find('input.currency'))
    setupAutoCompleteOnAddressFields()
  }
}
