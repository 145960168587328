import { Controller } from 'stimulus'

export class ModalForm extends Controller {
  static targets = ["firstName", "lastName", "businessOwnershipPercentage", "validatableInput"]

  connect() {
    this.modalView = new DSModal(this.element.querySelector('.modal-container.ds-modal'));

    if(this.element.classList.contains('persisted-record')){
      $(this.element).addClass('modal-form');
      $(this.element).find('.summary-container').removeClass('hidden');
    }else{
      this.modalView.show();
    }
    this._bindInputChangeValidation();
  }

  add(){
    this._validateAndUpdateSummary();
  }

  _isValid(){
    let isAllInputValid = true;
    let _this = this;
    this.validatableInputTargets.forEach(validatableInput =>{
      if(!_this._isValidInput(validatableInput)){
        isAllInputValid = false
      }
    });
    return isAllInputValid;
  }

  _validateInputOnChange(validatableInput){
    let _this = this;
    let inputElement = $(this._fetchInputElement(validatableInput));
    inputElement.on('change', function(){
      return _this._isValidInput(validatableInput);
    });
  }

  _bindInputChangeValidation(){
    let _this = this;
    this.validatableInputTargets.forEach(validatableInput =>{
      _this._validateInputOnChange(validatableInput);
    });
  }

  _isValidInput(validatableInput){
    let inputElement = $(this._fetchInputElement(validatableInput));
    let form = inputElement.closest('form');
    let validators = form[0].ClientSideValidations.settings.validators;
    inputElement.trigger('focusout');
    return inputElement.isValid(validators);
  }

  edit(){
    this.modalView.show()

    this._storePreviousValues();

    this.element.querySelector('.add-button-container').classList.add('hidden');
    this.element.querySelector('.save-button-container').classList.remove('hidden');

    $(this.element).removeClass('modal-form');
    $(this.element).find('.summary-container').addClass('hidden');

    $(this.element).find('.remove-link-container').addClass('hidden');
    $(this.element).find('.close-link-container').removeClass('hidden');
  }

  save(){
    this._validateAndUpdateSummary();
  }

  _validateAndUpdateSummary(){
    if(this._isValid()){
      this._updateSummaryFields();
      this.modalView.hide();
      $(this.element).addClass('modal-form');
      $(this.element).find('.summary-container').removeClass('hidden');
      this._afterSummaryUpdate();
    }
  }

  _updateSummaryFields(){
    let _this = this;
    _this.summaryFields.forEach(field =>{
      let inputElement;

      inputElement = _this.element.querySelector("[data-field-name='"+field+"']");
      inputElement = _this._fetchInputElement(inputElement);
      _this[field+"Target"].innerHTML = inputElement.value;
    });
  }

  _fetchInputElement(field){
    let inputElement;
    if(field.nodeName == "INPUT" || field.nodeName == "SELECT"){
      inputElement = field;
    }else{
      inputElement = field.querySelector('select, input');
    }
    return inputElement;
  }

  _storePreviousValues(){
    let _this = this;
    this.validatableInputTargets.forEach(validatableInput =>{
      let inputElement = _this._fetchInputElement(validatableInput);
      validatableInput.dataset.previousValue = inputElement.value;
      if(inputElement.type == 'checkbox'){
        validatableInput.dataset.previousValue = inputElement.checked;
      }
    });
  }

  _restorePreviousValues(){
    let _this = this;
    this.validatableInputTargets.forEach(validatableInput =>{
      let inputElement = _this._fetchInputElement(validatableInput);
      inputElement.value = validatableInput.dataset.previousValue;
      $(inputElement).trigger('change'); // update the dropdown selections      
      if(inputElement.type == "checkbox"){
        _this._restoreCheckboxSelection(validatableInput, inputElement);
      }
    });
  }

  _restoreCheckboxSelection(validatableInput, inputElement){
    if((validatableInput.dataset.previousValue == "true") || (validatableInput.dataset.previousValue == true) ){
      inputElement.checked = true;
      $(inputElement.closest('.field.boolean.checkbox .ui.checkbox')).checkbox('set checked');
    }else{
      inputElement.checked = false;
      $(inputElement.closest('.field.boolean.checkbox .ui.checkbox')).checkbox('set unchecked');
    }
  }

  hide(){
    this._restorePreviousValues();
    this.modalView.hide();
    $(this.element).addClass('modal-form');
    $(this.element).find('.summary-container').removeClass('hidden');
  }

  _afterSummaryUpdate(){
  }
}
