import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['sources', 'category', 'amount', 'duration', 'applicantEntry'];

  showIncomeSection() {
    this.sourcesTarget.style.display = "block";
    this._updateIncomeInputs(false);
  }

  hideIncomeSection() {
    this.sourcesTarget.style.display = "none";
    this._updateIncomeInputs(true);
  }

  _updateIncomeInputs(value) {
    [this.categoryTargets, this.amountTargets, this.durationTargets, this.applicantEntryTargets].forEach(function(targets, index){
        targets.forEach(function(inputTarget, inputIndex){
            inputTarget.disabled = value;
            if(value === true) {
              delete(inputTarget.dataset.validate);
            } else {
              inputTarget.dataset.validate = 'true';
            }
        });
    });
    $('input').filter(function(){
      return this.id.match(/loan_application_incomes_attributes_.*__destroy/)
    }).val(value);
  }
}