import { Controller } from "stimulus";

export default class extends Controller {

  connect(){
    this._bindBusinessStructureChange();
  }

  _bindBusinessStructureChange(){
    let businessStructure = $('.business-details-form-container .loan_application_business_entry_structure');
    businessStructure.change(this._setOwnershipPercentageToZeroForNonProfit.bind(this));
  }
  _setOwnershipPercentageToZeroForNonProfit(eventData){
    if($(eventData.target).val() == "non_profit"){
      let businessOwnershipPercentage = this.element.querySelector('.business-ownership-field input');
      businessOwnershipPercentage.value = 0;
    }
  }
}
