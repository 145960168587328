import { Controller } from 'stimulus'

export class Base extends Controller {
  _makeRequest(path) {
    Rails.ajax({
      type: 'GET',
      url: path,
      error: () => {
        toastr.error('Failed to load this section. Please reload the page and re-try.');
      }
    });
  }
}
