import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = []

  copy() {
    let link = this.data.get('link');
    navigator.clipboard.writeText(link);
    toastr.success('Link copied!')
  }
}
