import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["dashboardFilterForm", "statusField", "userIdField", "filtersContainer", "filtersLink", "pageField"]

  connect(){
    this.submitForm()
  }

  submitForm(){
    document.querySelector('.dashboard-body .ui.loader').classList.add("active")
    Rails.fire(this.dashboardFilterFormTarget, 'submit')
  }

  selectMultiple(event){
    this.userIdFieldTarget.value = event.target.dataset.user;
    event.target.classList.add("active");
    $(event.target.closest(".user-select-wrapper")).addClass('active').siblings().removeClass('active');
    this._unselectUser()
    this._resetPage()
    this.submitForm()
  }

  selectUser(event){
    var selectedUserId = event.target.value
    $(event.target.closest(".user-select-wrapper")).addClass('active').siblings().removeClass('active');
    this.userIdFieldTarget.value = selectedUserId
    this._resetPage()
    this.submitForm()
  }

  selectState(event){
      var selectedStatus = event.target.dataset.status
      this.statusFieldTarget.value = selectedStatus
      this._resetPage()
      this.submitForm()
  }

  selectPage(event){
      var selectedPage = event.target.dataset.page
      this.pageFieldTarget.value = selectedPage
      event.preventDefault()
      this.submitForm()
  }

    hideShowFilters(event){
        var _this = this
        if(this.filtersContainerTarget.classList.contains('hidden')){
            _this.filtersContainerTarget.classList.remove('hidden')
            _this.markLinkActive()
            animateCSS('.dashboard-body .filters-container', 'flipInX', function(){
                 _this.hideOnClickOutside(".dashboard-body .filters-container")
            });
        }else {
            animateCSS('.dashboard-body .filters-container', 'flipOutX', function(){
                _this.filtersContainerTarget.classList.add('hidden')
                _this.markLinkInactive()
            });
        }
    }

    hideOnClickOutside(selector) {
        var _this = this
        _this.outsideClickListener = (event) => {
            var $target = $(event.target);
            if($(event.target).closest(selector).length == 0 &&
               !($(event.target).is('.label.transition.visible .delete.icon')) &&
               ($(event.target).closest('.more-filter-link').length == 0)
              ){
                _this.hideShowFilters()
                _this.removeClickListener();
            }
        }

        _this.removeClickListener = () => {
            document.removeEventListener('click', _this.outsideClickListener)
        }

        document.addEventListener('click', _this.outsideClickListener)
    }

    markLinkActive(){
        this.filtersLinkTarget.classList.add('active')
    }
    markLinkInactive(){
        this.filtersLinkTarget.classList.remove('active')
    }

    search(){
        this.removeClickListener()
        this.submitForm()
        this.hideShowFilters()
    }

    reset(){
        $('.filters-container .ui.dropdown').dropdown('restore defaults');
        $('.filters-container #current-status-start-date').calendar('clear')
        $('.filters-container #current-status-end-date').calendar('clear')
        document.querySelector('#min-amount-filter').value = ""
        document.querySelector('#max-amount-filter').value = ""
    }

  _unselectUser(){
    document.getElementById("select-dashboard-user").selectedIndex = -1;
  }

  _resetPage(){
      this.pageFieldTarget.value = 1
  }
}
