import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['ssnField', 'viewSSNButton'];
  static values = {
    ssn: String,
    ssnVisible: Boolean
  };

  connect() {
    this.maskedSSN = this.ssnFieldTarget.innerHTML;
    this.ssn = this.ssnValue;
    this.viewSSNButtonText = 'View'
    this.hideSSNButtonText = 'Hide'
  }

  viewSSN() {
    this.ssnVisible = true;
    this.viewSSNButtonTarget.innerHTML = this.hideSSNButtonText;
    this.ssnFieldTarget.innerHTML = this.ssn;
  }

  hideSSN() {
    this.ssnVisible = false
    this.viewSSNButtonTarget.innerHTML = this.viewSSNButtonText;
    this.ssnFieldTarget.innerHTML = this.maskedSSN
  }

  toggleSSN() {
    if(this.ssnVisible){
      this.hideSSN()
    }else{
      this.viewSSN()
    }
  }
}
