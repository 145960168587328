import { Application, Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['substage'];
  INACTIVITY_TIME_IN_MILLISECONDS = 60000;
  ACTIVITY_EVENTS = ['click', 'mousemove', 'keydown'];

  connect() {
    this.substagePopupDisplayKey = `${this.substageTarget.value}_displayed`;
    this.isInactivityPopupDisplayed = (window.sessionStorage[this.substagePopupDisplayKey] === 'true');
    this.inactivityPopupDisplayHandler = this._inactivityPopupDisplayHandler.bind(this);

    if (!this.isInactivityPopupDisplayed) {
      this._inactivityPopupDisplayHandler();
      this.ACTIVITY_EVENTS.forEach(event => {
        document.addEventListener(event, this.inactivityPopupDisplayHandler);
      });
    }
  }

  _inactivityPopupDisplayHandler() {
    clearTimeout(this.timeOutReference);
    this.timeOutReference = setTimeout(() => {
      this._displayInactivityPopupMessage()
    }, this.INACTIVITY_TIME_IN_MILLISECONDS);
  }

  _displayInactivityPopupMessage() {
    $("#inactivity-alert-popup-modal").modal('show');
    window.sessionStorage[this.substagePopupDisplayKey] = 'true';
    this.ACTIVITY_EVENTS.forEach( event => {
      document.removeEventListener(event, this.inactivityPopupDisplayHandler);
    });
  }

  disconnect() {
    clearTimeout(this.timeOutReference);
    this.ACTIVITY_EVENTS.forEach( event => {
      document.removeEventListener(event, this.inactivityPopupDisplayHandler);
    });
  }
}
