import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['documentStatus']

  connect() {
    $(this.modalId).modal();
  }

  launchDocumentUploadModal() {
    $(this.modalId).modal('show');
  }

  updateStatusToReview() {
    let reviewStatus = 'client_submitted';
    let localePath = `loan_applications.documents_upload.statuses.${reviewStatus}`;
    this.documentStatusTarget.textContent = I18n.t(localePath);
    this.documentStatusTarget.dataset.localePath = localePath;
    this.element.dataset.status = reviewStatus;
  }

  moveToUnderReview() {
    $('.docs.under-review .doc-list-container')[0].appendChild(this.element);
  }

  get modalId() {
    return `#${this.data.get('modalId')}`;
  }
}
