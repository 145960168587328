import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['form', 'loanProduct', 'loanSubProduct', 'naicsCode', 'submitAnalysisInput']

  connect() {
    this._setupDropdowns();
  }

  saveAnalysis(event) {
    this.submitAnalysisInputTarget.disabled = true;
    $(this.formTarget).submit();
  }

  _setupDropdowns() {
    var _this = this;
    $(function(){
      if (_this.hasLoanProductTarget) {
        $(_this.loanProductTarget).find('.ui.dropdown').dropdown({
          placeholder: false,
          clearable: true
        });
      }
      if (_this.hasLoanSubProductTarget) {
        $(_this.loanSubProductTarget).find('.ui.dropdown').dropdown({
          placeholder: false,
          clearable: true
        });
      }
      if (_this.hasNaicsCodeTarget) {
        $(_this.naicsCodeTarget).find('.ui.dropdown').dropdown({
          fullTextSearch: 'exact'
        })
      }
    });
  }
}
