import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ['closeLinkContainer', 'verificationFormContainer', 'verificationForm', 'loanDetailsContainer', 'loanAmount', 'remainingBalance', 'primaryBorrower', 'loanClosingDate', 'businessName', 'refinanceApplicationLink', 'errorInfoContainer', 'formSubmitButton', 'loanderImage'];

  initialize() {
    this.modalView = $(this.element);
    this.modalView.modal({
      autofocus: true,
      closable: false,
      transition: 'fade',
      onShow: () => {
        this.verificationFormTarget.reset();
        this.loanDetailsContainerTarget.style.display = 'none';
        this.closeLinkContainerTarget.style.display = 'block';
        this.verificationFormContainerTarget.style.display = 'block';
        this.errorInfoContainerTarget.style.display = 'none';
        this.loanderImageTarget.style.display = 'none';
        this.modalView.find('form.new_refinance').enableClientSideValidations();
      }
    });

    this._setupSubmitCallbacks();
  }

  hide(){
    this.modalView.modal('hide');
  }

  cancelRefinanceApplication() {
    Rails.ajax({
      type: 'DELETE',
      url: this.cancelRefinanceApplicationPath
    });
    this.modalView.modal('hide');
  }

  returnToForm() {
    this.errorInfoContainerTarget.style.display = 'none';
    this.verificationFormContainerTarget.style.display = 'block';
  }

  _setupSubmitCallbacks(){
    this.verificationFormTarget.addEventListener('ajax:before', (event) => {
      this.formSubmitButtonTarget.style.display = 'none';
      this.loanderImageTarget.style.display = 'block';
    });
    this.verificationFormTarget.addEventListener('ajax:complete', (event) => {
      this.loanderImageTarget.style.display = 'none';
      this.formSubmitButtonTarget.style.display = 'block';
    });
    this.verificationFormTarget.addEventListener('ajax:success', (event) => {
      this.closeLinkContainerTarget.style.display = 'none';
      this._showLoanDetails(event.detail[0]);
      this.verificationFormContainerTarget.style.display = 'none';
      this.loanDetailsContainerTarget.style.display = 'block';
    });
    this.verificationFormTarget.addEventListener('ajax:error', (event) => {
      this.verificationFormContainerTarget.style.display = 'none';
      this._showErrorContainer(event.detail[0]);
      this.errorInfoContainerTarget.style.display = 'block';
    });
  }

  _showLoanDetails(details){
    this.loanAmountTarget.innerHTML = details['loan_amount'];
    this.remainingBalanceTarget.innerHTML = details['actual_balance'];
    this.primaryBorrowerTarget.innerHTML = details['primary_borrower']['name'];
    this.loanClosingDateTarget.innerHTML = details['closing_date'];
    this.businessNameTarget.innerHTML = details['business']['name'];
    this.refinanceApplicationLinkTarget.href = details['application_path'];
    this.cancelRefinanceApplicationPath = details['cancel_application_path'];
  }

  _showErrorContainer(errorDetails){
    this.errorInfoContainerTarget.dataset.error = errorDetails['error_type'];
  }

  get cancelRefinanceApplicationPath() {
    return this.data.get('cancelRefinanceApplicationPath');
  }

  set cancelRefinanceApplicationPath(path) {
    this.data.set('cancelRefinanceApplicationPath', path);
  }
}
