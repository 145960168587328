import { Controller } from "stimulus";

export default class extends Controller {

  connect(){
    this._markSelectedLocale();
  }

  handleSwitch(event){
    let locale = event.target.value;
    switchLanguageofForm(locale);
    this._updateHiddenLocaleFields(locale);
  }

  _updateHiddenLocaleFields(locale){
    $(".language-preference select").val(locale);
    $("input[name='locale']").val(locale);
  }

  _markSelectedLocale(){
    $(this.element).find('input[checked="checked"]').parents('.radio.ui.checkbox').addClass('checked')
  }
}
