import { Controller } from 'stimulus'

export default class extends Controller {
  connect() {
    this.url = this.data.get('url');
  }

  togglePledgeCollateral() {
    Rails.ajax({
      type: 'POST',
      url: this.url
    });
  }
}
