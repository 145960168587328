import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ['loading', 'autoApproveFailureReasons']

  initialize(){
    this.loanApplicationId = this.element.dataset.loanApplicationId
    this.shouldSubscribeToAutoApprovalEligibilityCheckChannel = this.element.dataset.shouldSubscribeToAutoApprovalEligibilityCheckChannel
    this.eligibilityCheckUrl = `/admin/loan_applications/${this.loanApplicationId}/trigger_auto_approval_eligibility_check`
    this._initiateEligibilityCheckChannelSubscription()
    $(this.loadingTarget).hide()
  }

  async triggerEligibilityCheck(){
    toastr.info("Initiating auto-approval eligibility check!")
    this._initiateEligibilityCheckChannelSubscription()
    $(this.loadingTarget).show()
    $(this.autoApproveFailureReasonsTarget).hide()

    try{
      const response = await fetch(this.eligibilityCheckUrl, {
        method: 'POST',
        headers: {
          "X-CSRF-Token": document.querySelector("[name='csrf-token']").content,
          'Content-Type': 'application/json'
        }
      })

      if(response.status === 202) return

      this._onErrorCallbackHandler()
    }catch(error){
      this._onErrorCallbackHandler()
      Bugsnag.notify(error)
    }
  }

  _initiateEligibilityCheckChannelSubscription(){
    if(!this._isSubscribedToEligibilityCheckChannel() && this.shouldSubscribeToAutoApprovalEligibilityCheckChannel === "true"){
      this._subscribeToEligibilityCheckChannel(this)
    }
  }

  _subscribeToEligibilityCheckChannel(controller){
    App.auto_approval_eligibility_check = App.cable.subscriptions.create(
      {
        channel: "AutoApprovalEligibilityCheckChannel",
        id: this.loanApplicationId
      },
      {
        connected: function() {
          // Called when the subscription is ready for use on the server
        },

        disconnected: function() {
           //Ask to reload page
        },

        received: function(data) {
          toastr.success("Updated 'Loan Details' with auto-approval eligibility check results!")
          $(controller.loadingTarget).hide()
          controller.autoApproveFailureReasonsTarget.innerHTML = data
          $(controller.autoApproveFailureReasonsTarget).show()
        }
      }
    );
  }

  _isSubscribedToEligibilityCheckChannel(){
    if(!App.auto_approval_eligibility_check) return false

    const subscriptions = App.auto_approval_eligibility_check.consumer.subscriptions.subscriptions
    const identifier = {channel: 'AutoApprovalEligibilityCheckChannel', id: this.loanApplicationId}
    return subscriptions.filter((s) => s.identifier === JSON.stringify(identifier) ).length > 0
  }

  _onErrorCallbackHandler(){
    toastr.error("Failed to initiate auto-approval eligibility check. Please contact support.")
    $(this.loadingTarget).hide()
    $(this.autoApproveFailureReasonsTarget).show()
  }
}