import { Controller } from 'stimulus'

export default class extends Controller {

  connect() {
    this._initializePlaid();
    this.successView = new DSModal(this.element.querySelector('#plaid-connection-success'));
    this.waitView = new DSModal(this.element.querySelector('#plaid-connection-wait'));
    this.errorView = new DSModal(this.element.querySelector('#plaid-connection-error'));
  }

  addNewBankAccount(event) {
    this.handler.open();
  }

  closeSuccessModal(){
    this.successView.hide();
  }

  closeErrorModal(){
    this.errorView.hide();
  }

  _onLoadCallbackHandler() {
  }

  _onSuccessCallbackHandler(public_token, metadata){
    this.handler.exit( { force: false } )
    var formData = new FormData();
    formData.append("data", JSON.stringify({
      metadata: metadata,
      applicant_entry_id: this.applicantId,
      loan_application_id: this.loanApplicationId
    }));
    this._requestForAccountData(formData);
  }

  _refreshLinkToken(){
    let _this = this;
    var formData = new FormData();
    formData.append("data", JSON.stringify({
      applicant_entry_id: this.applicantId,
      loan_application_id: this.loanApplicationId
    }));

    Rails.ajax({
      type: 'POST',
      url: `/plaid/refresh_link_token`,
      data: formData,
      dataType: 'script',
      success: function(linkTokenData){
        _this._updatePlaidLink(linkTokenData);
        _this.waitView.hide();
        _this.successView.show();
      },
      error: function(){
        _this.waitView.hide();
        _this.errorView.show();
      }
    });
  }

  _updatePlaidLink(linkTokenData){
    this.handler.destroy();
    this.plaidLinkToken = linkTokenData["link_token"];
    this._initializePlaid();
  }

  _requestForAccountData(data) {
    this.waitView.show();
    let _this = this
    Rails.ajax({
      type: 'POST',
      url: `/plaid/get_access_token`,
      data: data,
      dataType: 'script',
      success: function(){
        _this._refreshLinkToken();
      },
      error: function(){
        _this.waitView.hide();
        _this.errorView.show();
      }
    });
  }

  _onExitCallbackHandler(error, metadata){
    if (error != null) {
      this.errorView.show();
      Bugsnag.notify("Plaid::OnExit", "exited with error ", metadata)
    }
  }

  _onEventCallbackHandler(eventName, metadata){
    if(["ERROR", "CLOSE_OAUTH", "FAIL_OAUTH"].includes(eventName)) {
      Bugsnag.notify("Plaid::OnEvent", "Event callback for " + eventName, metadata)
    }
  }

  get applicantId(){
    return this.data.get('applicantIdentifier')
  }

  get languagePreference() {
    return this.data.get('languagePreference')
  }

  get plaidLinkToken() {
    return this.data.get('plaidLinkToken')
  }

  set plaidLinkToken(linkToken){
    this.data.set('plaidLinkToken', linkToken);
  }

  get loanApplicationId(){
    return this.data.get('loanApplicationId')
  }

  _initializePlaid() {
    var _this = this;
    this.handler = Plaid.create({
      token: this.plaidLinkToken,
      language: this.languagePreference,
      onLoad: _this._onLoadCallbackHandler.bind(_this),
      onSuccess: _this._onSuccessCallbackHandler.bind(_this),
      onExit: _this._onExitCallbackHandler.bind(_this),
      onEvent: _this._onEventCallbackHandler.bind(_this)
    });
  }
}
